import moment from "moment";

/******************************************************************************************** */
/* Date/time helper functions                                                                 */
/******************************************************************************************** */
export const isDefaultDate = (date) => moment(date).format("YYYY") === "0001";

export const formatDate = (datetime) => {
	if (
		!datetime ||
		datetime === "" ||
		moment(datetime).format("YYYY") === "0001"
	) {
		return "-";
	}
	if (moment(datetime).isValid()) {
		return moment.utc(datetime).local().format("Do MMMM YYYY"); // Convert from UTC to local time
	}
	return moment.utc(datetime, "MMM DD YYYY HH:mmAA").local().format("Do MMMM YYYY"); // Convert from UTC to local time
};

export const formatDateTime = (datetime) => {
	let format = "h:mm a, D MMM YYYY";
	if (!datetime) {
		return "-";
	}
	if (moment(datetime).isValid()) {
		// Convert UTC to local time (browser's timezone) before formatting
		return moment.utc(datetime).local().format(format); // Convert from UTC to local time
	}
	return moment.utc(datetime, "MMM DD YYYY HH:mmAA").local().format(format); // Convert from UTC to local time
};

export const formatTime = (time) => {
	if (!time || time === "") {
		return "-";
	}
	if (moment(time).isValid()) {
		return moment.utc(time, ["HH:mm"]).local().format("h:mm a"); // Convert from UTC to local time
	}
	if (/^([01]\d|2[0-3]):?([0-5]\d)$/g.test(time)) {
		//For new entry formatted HH:mm
		return moment.utc(time, "HH:mm").local().format("h:mm a"); // Convert from UTC to local time
	}
	return moment.utc(time, "MMM DD YYYY HH:mmAA").local().format("h:mm a"); // Convert from UTC to local time
};

// used in add/edit form post effects for geolocation timestamp
export const getCurrentTimestamp = () => moment().format("D MMM YYYY h:mm A");

export const dayDifference = (startDate, endDate) => {
	let start = moment(startDate);
	let end = moment(endDate);

	return end.diff(start, "days");
};
