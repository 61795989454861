/******************************************************************************************** */
/* Validation helper functions                                                                */
/******************************************************************************************** */

const OK = "";
const INVALID_EMAIL_ADDRESS = "Email address invalid";
const INVALID_PHONE_NUMBER = "Phone number must be 7 or characters";
const MIN_FIELD_LENGTH = 1;
const MIN_PASSWORD_LENGTH = 7;
const MIN_PHONE_NUMBER_LENGTH = 8;
const INVALID_PASSWORD = "Password must be 7 or more characters";

export function validateEmailAddress(emailAddress) {
	if (!emailAddress || emailAddress === "") {
		return OK;
	}

	/* eslint-disable */
	let re =
		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	/* eslint-enable */
	if (!re.test(String(emailAddress).toLowerCase())) {
		return INVALID_EMAIL_ADDRESS;
	}

	return OK;
}

export function validatePhoneNumber(phoneNumber) {
	if (!phoneNumber || phoneNumber === "") {
		return OK;
	}

	if (phoneNumber.length <= MIN_PHONE_NUMBER_LENGTH) {
		return INVALID_PHONE_NUMBER;
	}

	return OK;
}

export function validatePassword(password) {
	if (!password || password === "") {
		return OK;
	}

	if (password.length < MIN_PASSWORD_LENGTH) {
		return INVALID_PASSWORD;
	}

	return OK;
}

export function hasValue(value) {
	let valid = value && value.length >= MIN_FIELD_LENGTH;
	return !!valid;
}

export function hasNumber(myString) {
	return /[0-9]/.test(myString);
}

// check if value is safe for MUIRichTextEditor value property
export const isValidContentState = (value) => {
	try {
		if (!value) return false;
		const parsed = JSON.parse(value);
		return parsed && typeof parsed === 'object' && 'entityMap' in parsed && 'blocks' in parsed;
	} catch (e) {
		console.warn('Invalid content state:', e);
		return false;
	}
};

// checks if list is not null or empty
export const isNotNullOrEmpty = (list) => list && list.length > 0;

// checks if is default guid
export const isDefaultGuid = (guid) =>
	guid && guid === "00000000-0000-0000-0000-000000000000";

// checks list is same length
export const isSameLength = (listA, listB) =>
	listA && listB && listA.length === listB.length;

// used in add/edit stafftraining form and add/edit incident form
export const validatePeopleWithoutRoles = (
	people,
	peopleBlockRef,
	setInvalidPeople
) => {
	let valid = true;
	if (isNotNullOrEmpty(people)) {
		const hasNullRoles = people.filter((p) => p.checked && p.role === "");

		if (isNotNullOrEmpty(hasNullRoles)) {
			valid = false;

			if (peopleBlockRef.current) {
				peopleBlockRef.current.scrollIntoView({
					behavior: "smooth",
					block: "start",
				});
			}
		}
	}
	setInvalidPeople(!valid);
	return valid;
};

// filters out staff with unselected role or unchecked staff
// used in createorupdate effects to clean people object before posting
export const filterPeople = (peopleList) => {
	let cleanedPeopleList = peopleList;

	if (isNotNullOrEmpty(peopleList)) {
		cleanedPeopleList = cleanedPeopleList.filter(
			(p) => isValid(p.role) && p.checked
		); // filter people with no role selected and unchecked
	}

	// map object for api DTO
	cleanedPeopleList = cleanedPeopleList.map((p) => ({
		id: p.id || p.value || "",
		role: p.role,
		name: p.name || p.label || "",
	}));

	return cleanedPeopleList;
};

export const isValid = (value) => value && value !== "";

export const isValidRole = (role) =>
	isValid(role) &&
	[
		"Account Administrator",
		"Staff User",
		"Staff Non User",
		"Consultant",
	].includes(role);

export const isValidEmail = (email) => {
	if (!email || email === "") return false;

	let domain = email.split(/@/)[1];
	let ext = domain && domain.split(/\./)[1];
	let isValidDomain = ext && ext.length > 1; // to avoid single character email extension
	let isEmailFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

	return isEmailFormat && isValidDomain;
};
