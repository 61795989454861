import React, { useCallback, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { ValidatorForm } from "react-material-ui-form-validator";
import { strip } from "features/forms";
import { formatDate, formatTime } from "utils";
import moment from "moment";
import {
	Card,
	CardContent,
	Grid,
	TextField,
	Typography,
	Link,
	Box,
} from "@material-ui/core";
import {
	FormActions,
	FormButton,
	FormSubmitButton,
	Page,
	PageContent,
	ScrollToTopOnMount,
	SiteName,
	StaffName,
	TaskType,
	PageHeader,
	SelectInput,
	PopupSnackbar
} from "frame/components";
import { getGoogleMapsLinkByLocationQuery } from "utils";
import { connect } from "react-redux";
import {
	onAddTimesheetValueChange,
	onConfirmActiveTimesheet,
	onDiscardActiveTimesheet,
	onAddTimesheetStopTimer
} from "../timesheet-actions";
import { ADD_TIMESHEET_ROUTE } from "../timesheet-routes";

const useStyles = makeStyles((theme) => ({
	root: {},
	body: {
		marginTop: theme.spacing(-2),
		[theme.breakpoints.down("xs")]: {
			marginTop: theme.spacing(3),
		},
	},
	actions: {
		marginTop: theme.spacing(3),
		paddingBottom: theme.spacing(8),
	},
	menu: {
		padding: theme.spacing(3, 0, 0, 0),
		"& > *": {
			marginRight: theme.spacing(3),
		},
	},
	label: { color: "#999999" },
	pageHeader: {
		marginBottom: theme.spacing(-5),
	},
	discardButton: {
		color: theme.palette.white,
		background: theme.palette.error.main,
		borderColor: theme.palette.error.main,
		"&:hover": {
			background: theme.palette.error.dark,
			borderColor: theme.palette.error.dark,
		},
		marginRight: 0
	},
}));

const mapStateToProps = ({ timesheetFormReducer, profile, geolocation }) => ({
	values: timesheetFormReducer,
	role: profile.role,
	latitude: geolocation.latitude,
	longitude: geolocation.longitude,
});

const mapDispatchToProps = {
	onChange: onAddTimesheetValueChange,
	onSave: onConfirmActiveTimesheet,
	onDelete: onDiscardActiveTimesheet,
	onStopTimer: onAddTimesheetStopTimer,
};

export const ConfirmTimesheetForm = connect(
	mapStateToProps,
	mapDispatchToProps
)(({ values, onChange, onSave, role, onDelete, onStopTimer, latitude, longitude }) => {
	const classes = useStyles();
	const timesheet = values.timesheet || {};

	const onSubmit = (event) => {
		event.preventDefault();
		onSave(strip(values));
	};

	const onCancel = () => {
		onDelete(values.timesheet.id);
	};

	const onInputChange = useCallback(
		(key) => (event) => {
			const value =
				(event.target && event.target.value) ||
				(typeof event === "string" && event) ||
				"";
			onChange({
				...values,
				timesheet: {
					...timesheet,
					[key]: value,
				},
			});
		},
		[timesheet, values, onChange]
	);

	const startLocationGoogleMapLink =
		timesheet.startLatitude && timesheet.startLongitude
			? getGoogleMapsLinkByLocationQuery(
				timesheet.startLatitude,
				timesheet.startLongitude
			)
			: null;

	const endLocationGoogleMapLink =
		timesheet.endLatitude && timesheet.endLongitude
			? getGoogleMapsLinkByLocationQuery(
				timesheet.endLatitude,
				timesheet.endLongitude
			)
			: null;

	useEffect(() => {
		onStopTimer({
			latitude,
			longitude,
		});
	}, []);

	return (
		<Page title="Add Timesheet">
			<ScrollToTopOnMount />

			<PageHeader
				className={classes.pageHeader}
				canGoBack
				backActionPath={ADD_TIMESHEET_ROUTE}
				title="Review and Save Timesheet"
			/>

			<PageContent padSides padBottom>
				<ValidatorForm
					autoComplete="off"
					noValidate
					onSubmit={onSubmit}
				>
					<Card>
						<CardContent>
							<Grid container spacing={2}>
								<Grid item md={6} xs={12}>
									<Typography
										variant="body2"
										className={classes.label}
									>
										Site Name
									</Typography>
									<Typography variant="h5">
										<SiteName id={values.siteId} />
									</Typography>
								</Grid>
								<Grid item md={6} xs={12}>
									<Typography
										variant="body2"
										className={classes.label}
									>
										Staff Name
									</Typography>
									<Typography variant="h5">
										<StaffName id={timesheet.staffId} />
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography
										className={classes.label}
										variant="body2"
									>
										Started At
									</Typography>
									<Typography
										className={classes.times}
										variant="h5"
									>
										{moment(timesheet.startDate + ' ' + timesheet.startTime, "YYYY-MM-DD HH:mm").format("h:mm a, D MMM YYYY")}
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography
										variant="body2"
										className={classes.label}
									>
										Start Location
									</Typography>
									<Typography variant="h5">
										{startLocationGoogleMapLink ? (
											<Link
												href={
													startLocationGoogleMapLink
												}
												color="secondary.dark"
												variant="inherit"
												target="_blank"
												rel="noreferrer"
											>
												View on Maps
											</Link>
										) : (
											"N/A"
										)}
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography
										className={classes.label}
										variant="body2"
									>
										Ended At
									</Typography>
									<Typography
										className={classes.times}
										variant="h5"
									>
										{moment(timesheet.endDate + ' ' + timesheet.endTime, "YYYY-MM-DD HH:mm").format("h:mm a, D MMM YYYY")}
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography
										variant="body2"
										className={classes.label}
									>
										End Location
									</Typography>
									<Typography variant="h5">
										{endLocationGoogleMapLink ? (
											<Link
												href={endLocationGoogleMapLink}
												color="secondary.dark"
												variant="inherit"
												target="_blank"
												rel="noreferrer"
											>
												View on Maps
											</Link>
										) : (
											"N/A"
										)}
									</Typography>
								</Grid>
								<Grid item md={4} xs={12}>
									<SelectInput
										label="Lunch Time"
										options={[
											{
												label: "None",
												value: "0",
											},
											{
												label: "30 mins",
												value: "30",
											},
											{
												label: "60 mins",
												value: "60",
											},
										]}
										value={timesheet.lunchTime || "0"}
										onChange={onInputChange("lunchTime")}
										noNone
									/>
								</Grid>
								<Grid item md={2} xs={0}>
								</Grid>
								<Grid item md={6} xs={12}>
									<Typography
										variant="body2"
										className={classes.label}
									>
										Task Type
									</Typography>
									<Typography variant="h5">
										<TaskType id={values.organisationTaskTypeId} />
									</Typography>
								</Grid>
								<Grid md={8} xs={0}/>
								<Grid item xs={12}>
									<TextField
										id="timesheet_notes"
										fullWidth
										label="Notes"
										multiline
										rows="3"
										value={timesheet.notes || ""}
										variant="outlined"
										onChange={onInputChange("notes")}
									/>
								</Grid>
							</Grid>
						</CardContent>
					</Card>
					<FormActions className={classes.actions}>
						<FormSubmitButton
							loading={values.isLoading}
						>
							Save
						</FormSubmitButton>
						<div style={{ flex: 1 }}/>
						<FormButton
							className={classes.discardButton}
							loading={values.isLoading}
							disabled={values.isLoading || !values.timesheet.id}
							onClick={onCancel}
						>
							Discard
						</FormButton>
					</FormActions>
				</ValidatorForm>
				<PopupSnackbar
					message={values.errorMessage}
					coloured
					error
					onExited={() => {}}
				/>
			</PageContent>
		</Page>
	);
});
