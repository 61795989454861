import { of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { catchError, filter, mergeMap, delay } from "rxjs/operators";
import { ofType } from "redux-observable";
import { tokenExists, getBaseUrl, authHeaders } from "frame";
import fromAuth from "features/authorisation/actions/types";
import fromFrame from "frame/actions/types";
import * as fromTypes from "../actions/types";
import * as fromActions from "../actions";

// separated so that fetch flags will update for ORGANISATION_FETCH_REQUESTED in reducer
export const triggerFetchOrganisationEffect = (action$, state$) =>
    action$.pipe(
        ofType(
            fromAuth.AUTHORISATION_LOGIN_FULFILLED,
            fromFrame.FRAME_INITIALISE_REQUESTED
            // fromTypes.ORGANISATIONS_CURRENT_ORGANISATION_CHANGED
        ),
        mergeMap((action) => {
            console.log('triggerFetchOrganisationEffect triggered by:', action.type);
            return of(fromActions.organisationFetch());
        })
    );

    
    export const organisationListEffect = (action$, state$) =>
        action$.pipe(
            ofType(fromTypes.ORGANISATION_FETCH_REQUESTED),
            delay(500),
            filter(() => tokenExists()),
            filter(() => state$.value.queue.hasNetwork),
            mergeMap(() => {
                console.log('organisationListEffect making API call');
                return ajax
                    .get(`${getBaseUrl()}/api/organisation/all`, authHeaders())
                    .pipe(
                        mergeMap((ajaxResponse) =>
                            of(
                                fromActions.organisationFetchSuccess(
                                    ajaxResponse.response
                                )
                            )
                        ),
                        catchError((error) =>
                            of(fromActions.organisationFetchFailed(error.message))
                        )
                    );
            })
        );

export const organisationRefetchListEffect = (action$, state$) =>
    action$.pipe(
        ofType(fromTypes.ORGANISATION_REFETCH_LIST),
        delay(500),
        filter(() => tokenExists()),
        filter(() => state$.value.queue.hasNetwork),
        mergeMap(() => {
            console.log('organisationRefetchListEffect making API call');
            return ajax
                .get(`${getBaseUrl()}/api/organisation/all`, authHeaders())
                .pipe(
                    mergeMap((ajaxResponse) =>
                        of(
                            fromActions.organisationRefetchListSuccess(
                                ajaxResponse.response
                            )
                        )
                    ),
                    catchError((error) => {
                        return of(
                            fromActions.organisationRefetchListFailed(
                                error.message
                            )
                        );
                    })
                );
        })
    );