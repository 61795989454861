import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import JobCard from "./job-card";
import { PageHeaderTabs } from "features/health-safety-forms";
import { ADD_JOB_PATH } from "../actions/types";
import { Typography } from "@material-ui/core";
import { Page, PageContent, SearchBar, PopupSnackbar, SendEmailModal } from "frame/components";
import { getPageResults } from "utils";
import { TASK_HOME_ROUTE } from "features/home/home-routes";
import { safetyWiseApi } from "utils/safetyWiseApi";
import InfiniteScroll from "react-infinite-scroll-component";
import { CardText } from "features/timesheet/components/timesheet-list";
import { initialJobState } from "../reducers/jobs-reducer";
import EditJobModal from "./edit-job-modal";

const useStyles = makeStyles((theme) => ({
	root: {},
	heading: {
		marginTop: theme.spacing(4),
		[theme.breakpoints.down("xs")]: {
			marginTop: theme.spacing(0)
		}
	},
	body: {
		marginTop: theme.spacing(2),
		[theme.breakpoints.down("xs")]: {
			marginTop: theme.spacing(3)
		}
	},
	filter: { display: "flex" }
}));

const JobList = ({ currentUserId, role, hasNetwork, isSummaryList, currentOrganisationGUID }) => {
	const classes = useStyles();

	const [showEmailModal, setShowEmailModal] = useState(false);
	const [isSendingEmail, setIsSendingEmail] = useState(false);
	const [selectedItemId, setSelectedItemId] = useState(null);
	const [errorMessage, setErrorMessage] = useState("");
	const [successMessage, setSuccessMessage] = useState("");
	const [selectedItemType, setSelectedItemType] = useState(null);
	const [siteId, setSiteId] = useState(null);
	const [customerId, setCustomerId] = useState(null);

	const ref = useRef(null);
	const [height, setHeight] = useState(0);
	const [jobList, setJobList] = useState(getPageResults(initialJobState));
	const [hasMoreJobs, setHasMoreJobs] = useState(true);
	const [currentPage, setCurrentPage] = useState(1);
	const [isDoneFetching, setIsDoneFetching] = useState(false);
	const [totalNumberOfRecords, setTotalNumberOfRecords] = useState(1);
	const defaultPageSize = 20;
	const [searchFilter, setSearchFilter] = useState("");
	const [showEditModal, setShowEditModal] = useState(false);

	const empty = jobList && jobList.length <= 0;

	const onEmail = (id, siteId, selectedItemType, customerId) => () => {
		setSelectedItemId(id);
		setSiteId(siteId);
		setCustomerId(customerId);
		setSelectedItemType(selectedItemType);
		setShowEmailModal(true);
	};

	useEffect(() => {
		getJobs(1, true);
	}, [searchFilter]); // Add searchFilter as dependency

	const setNewJobState = (jobResult, page) => {
		let newState = initialJobState;

		if (jobResult && jobResult.results && jobResult.results.length > 0) {
			newState = {
				...newState,
				...jobResult
			};
		}

		newState.refreshList = jobResult.refreshList;
		newState.flag = "done";
		// If navigating to first page clear the resuls from timesheetList otherwise add to the end of it
		if (page === 1) {
			setJobList([...getPageResults(newState)]);
		} else {
			setJobList([...jobList, ...getPageResults(newState)]);
		}
		setIsDoneFetching(true);
	};

	const getJobs = (
		fromPageToRequest = currentPage, // fromPageToRequest is the page to request from the API,
		recordDeletedOrUpdated = false, // recordDeletedOrUpdated is a boolean to determine whether to overwrite or append to jobLists
		pageSize = defaultPageSize // pageSize is the number of records to load from the API, can be paramaterised to request more data.
	) => {
		if (totalNumberOfRecords !== jobList.length || recordDeletedOrUpdated) {
			if (recordDeletedOrUpdated) {
				setHasMoreJobs(true);
			}

			safetyWiseApi
				.post(`${currentOrganisationGUID}/job/paged`, {
					ascending: false,
					page: fromPageToRequest,
					pageSize,
					orderBy: "createdAt",
					searchKey: searchFilter
				})
				.then((result) => {
					setNewJobState(result.data, fromPageToRequest);

					// If more then the defaultPageSize data is requested from first page then dynamically set the current page, else set to fromPageToRequest
					if (pageSize > defaultPageSize && fromPageToRequest === 1) {
						setCurrentPage(pageSize / defaultPageSize);
					} else {
						setCurrentPage(fromPageToRequest);
					}

					setTotalNumberOfRecords(result.data.totalNumberOfRecords);
					if (result.data.totalNumberOfRecords == jobList.length + result.data.results.length) {
						setHasMoreJobs(false);
					}
				});
		} else {
			setHasMoreJobs(false);
		}
	};

	const handleApiError = (error) => {
		if (error.response && error.response.status === "401") {
			setErrorMessage("You are not authorized to perform this action.");
		} else if (error.response && error.response.status === "404") {
			setErrorMessage("The record no longer exists.");
		} else {
			setErrorMessage("Something went wrong, please refresh the page and try again.");
		}
	};

	let emailListType = "";

	if (selectedItemType === "site") {
		emailListType = "relatedToParticularSite";
	} else if (selectedItemType === "staff") {
		emailListType = "allCustomerSiteRecord";
	} else if (selectedItemType === "customer") {
		emailListType = "relatedToParticularCustomer";
	}

	// The InfiniteScroll Component requires a height for it to work  effectively and for it resize relative to the parent.
	useEffect(() => {
		const handleResize = () => {
			setHeight(ref.current.offsetHeight - 20); // reduce height by 20px ottherwise the scrollbar flows past the height of screen.
		};
		handleResize();
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<Page className={classes.root} title="Photo / Notes" style={{ height: "100%" }}>
			<div ref={ref} style={{ backgroundColor: "", height: "100%", overflow: "hidden" }}>
				{/* <p>
					{totalNumberOfRecords} {jobList.length}
				</p> */}
				{/* n.o of records and length of loaded list */}

				<InfiniteScroll
					dataLength={jobList.length}
					next={() => {
						getJobs(currentPage + 1);
					}}
					hasMore={hasMoreJobs}
					loader={<CardText message={"Loading..."} />}
					height={height + "px"}
					scrollThreshold={0.99}
					style={{ paddingBottom: "10px" }}
				>
					{!isSummaryList && (
						<>
							<PageHeaderTabs
								title="View Photo or Note records."
								addTabPath={ADD_JOB_PATH}
								staffUserHide={false}
								backActionPath={TASK_HOME_ROUTE}
							/>

							<div className={classes.filter}>
								<SearchBar onSearch={(s) => {
									setSearchFilter(s);
									// Reset to page 1 and trigger new search
									// Only trigger search if string is empty (reset) or 3+ characters
									// if (s === "" || s.length >= 3) {
									 	getJobs(1, true);
									// }
								}} />
							</div>
						</>
					)}

					<PageContent pad>
						{isSummaryList && (
							<Typography variant="h2" className={classes.heading}>
								Photo / Notes
							</Typography>
						)}

						<div className={classes.body}>
							<p style={{ marginTop: 5 }}>
								{jobList.length > 0 ? totalNumberOfRecords + " records" : "No records loaded"}
							</p>

							{!empty &&
								jobList.map((j, index) => (
									<JobCard
										key={index}
										job={j}
										role={role}
										currentUserId={currentUserId}
										hasNetwork={hasNetwork}
										onEdit={(id) => {
											setSelectedItemId(id);
											setShowEditModal(true);
										}}
										onDelete={() => {
											safetyWiseApi
												.del(`${currentOrganisationGUID}/job/delete/${j.id}`)
												.then(() => {
													//jobIndex + 1 to get position in array, then get the next n(pageSize)TH multiple which will be the page size to load
													const pageSizeToLoad =
														Math.ceil((index + 1) / defaultPageSize) * defaultPageSize;

													getJobs(1, true, pageSizeToLoad);
												});
										}}
										onEmail={onEmail(j.id, j.siteId, j.type, j.customerId)}
									/>
								))}
						</div>

						{empty && (
							<Typography variant="body1">There are currently no photos or notes.</Typography>
						)}
					</PageContent>
					<SendEmailModal
						isOpen={showEmailModal}
						title={"Email Photo or Note Record"}
						onClose={() => setShowEmailModal(false)}
						onSend={(selectedRecipients) => {
							setIsSendingEmail(true);
							safetyWiseApi
								.post(`${currentOrganisationGUID}/job/email/${selectedItemId}`, {
									toInternalAddresses: selectedRecipients.recipients,
									toExternalAddresses: [
										...selectedRecipients.recipientsOther,
										...selectedRecipients.recipientsCustomerSites
									]
								})
								.then(() => {
									setSuccessMessage("Emails sent successfully.");
								})
								.catch(handleApiError)
								.finally(() => {
									setIsSendingEmail(false);
									setShowEmailModal(false);
								});
						}}
						isSaving={isSendingEmail}
						siteId={siteId}
						emailListType={emailListType}
						customerId={customerId}
					/>
					<PopupSnackbar
						message={errorMessage}
						coloured
						error
						onExited={() => setErrorMessage("")}
					/>
					<PopupSnackbar
						message={successMessage}
						coloured
						success
						onExited={() => setSuccessMessage("")}
					/>
					{showEditModal && (
						<EditJobModal
							selectedJob={jobList.find((x) => x.id === selectedItemId)}
							onClosed={(s) => {
								if (s === "saved") {
									const jobIndex = jobList.findIndex((j) => j.id === selectedItemId); // find JobIndex
									//jobIndex + 1 to get position in array, then get the next n(pageSize)TH multiple which will be the page size to load
									const pageSizeToLoad =
										Math.ceil((jobIndex + 1) / defaultPageSize) * defaultPageSize;

									getJobs(1, true, pageSizeToLoad);

									setShowEditModal(false);
								} else {
									setShowEditModal(false);
								}
							}}
							open={showEditModal}
						/>
					)}
					{!isDoneFetching && <CardText message={"Loading..."} style={{ marginTop: "20px" }} />}
					{!hasMoreJobs && <CardText message={"All photos have been loaded."} />}
					{jobList.length === 0 && isDoneFetching && (
						<CardText message={"No timesheets to show."} />
					)}
				</InfiniteScroll>
			</div>
		</Page>
	);
};

JobList.propTypes = {
	currentUserId: PropTypes.string.isRequired,
	role: PropTypes.string.isRequired,
	onAdd: PropTypes.func,
	onEdit: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
	onSearchChanged: PropTypes.func.isRequired,
	hasNetwork: PropTypes.bool.isRequired,
	isSummaryList: PropTypes.bool,
	pagination: PropTypes.object.isRequired,
	searchFilter: PropTypes.string,
	// onGetJobList: PropTypes.func.isRequired,
	flags: PropTypes.object.isRequired
};

export default JobList;
