import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import {
	Page,
	PageContent,
	ScrollToTopOnMount,
	SearchBar,
	Pagination,
	PaginationHeader,
	PaginationLoader,
	SendEmailModal,
	PopupSnackbar
} from "frame/components";
import * as fromHealthSafetyForm from "features/health-safety-forms";
import AccidentCard from "../cards/accident-card";
import { AppendIncidentForm, EditIncidentForm, PageHeaderTabs } from "features/health-safety-forms";
import { getPageResults, useIsOnline } from "utils";
import { safetyWiseApi } from "utils/safetyWiseApi";
import { initialJobState } from "features/health-and-safety/reducers/health-safety-reducer";
import { CardText } from "features/timesheet/components/timesheet-list";
import InfiniteScroll from "react-infinite-scroll-component";

const useStyles = makeStyles((theme) => ({
	root: {},
	filter: { display: "flex" }
}));

const IncidentList = ({
	incidents,
	onDelete,
	profile,
	currentOrganisationGUID,
	onSearchChanged
	// searchFilter,
	// onGetIncidentList
}) => {
	const isOnline = useIsOnline();
	const classes = useStyles();
	const [showAppendModal, setShowAppendModal] = useState(false);
	const [showViewModal, setShowViewModal] = useState(false);
	const [showEmailModal, setShowEmailModal] = useState(false);
	const [isSendingEmail, setIsSendingEmail] = useState(false);
	const [selectedItemId, setSelectedItemId] = useState(null);
	const [siteId, setSiteId] = useState(null);
	const [errorMessage, setErrorMessage] = useState("");
	const [successMessage, setSuccessMessage] = useState("");

	const [incidentList, setIncidentList] = useState(getPageResults(initialJobState));
	const [currentPage, setCurrentPage] = useState(1);
	const defaultPageSize = 20;
	const [totalNumberOfRecords, setTotalNumberOfRecords] = useState(1);
	const [hasMoreIncidents, setHasMoreIncidents] = useState(true);
	const [isDoneFetching, setIsDoneFetching] = useState(false);
	const ref = useRef(null);
	const [height, setHeight] = useState(0);
	const [searchFilter, setSearchFilter] = useState("");

	// const incidentList = getPageResults(incidents);
	const empty = incidentList && incidentList.length <= 0;

	const onAppend = (id) => () => {
		setSelectedItemId(id);
		setShowAppendModal(true);
	};
	const onView = (id) => () => {
		setSelectedItemId(id);
		setShowViewModal(true);
	};
	const onEmail = (id, siteId) => () => {
		setSelectedItemId(id);
		setSiteId(siteId);
		setShowEmailModal(true);
	};
	const handleApiError = (error) => {
		if (error.response && error.response.status === "401") {
			setErrorMessage("You are not authorized to perform this action.");
		} else if (error.response && error.response.status === "404") {
			setErrorMessage("The record no longer exists.");
		} else {
			setErrorMessage("Something went wrong, please refresh the page and try again.");
		}
	};
	const isFetching = incidents.flag === "processing" && isOnline;
	// const isDoneFetching = incidents.flag === "done" || !isOnline;

	const setNewIncidentState = (incidentResult, page) => {
		let newState = initialJobState;

		if (incidentResult && incidentResult.results && incidentResult.results.length > 0) {
			newState = {
				...newState,
				...incidentResult
			};
		}

		newState.refreshList = incidentResult.refreshList;
		newState.flag = "done";
		// If navigating to first page clear the resuls from incidentList otherwise add to the end of it
		if (page === 1) {
			setIncidentList([...getPageResults(newState)]);
		} else {
			setIncidentList([...incidentList, ...getPageResults(newState)]);
		}
		setIsDoneFetching(true);
	};

	const getIncidents = (
		fromPageToRequest = currentPage, // fromPageToRequest is the page to request from the API,
		recordDeletedOrUpdated = false, // recordDeletedOrUpdated is a boolean to determine whether to overwrite or append to incidentLists
		pageSize = defaultPageSize // pageSize is the number of records to load from the API, can be paramaterised to request more data.
	) => {
		if (totalNumberOfRecords !== incidentList.length || recordDeletedOrUpdated) {
			if (recordDeletedOrUpdated) {
				setHasMoreIncidents(true);
			}

			safetyWiseApi
				.post(`${currentOrganisationGUID}/accident/paged`, {
					ascending: false,
					page: fromPageToRequest,
					pageSize,
					orderBy: "createdAt",
					searchKey: searchFilter
				})
				.then((result) => {
					setNewIncidentState(result.data, fromPageToRequest);

					// If more then the defaultPageSize data is requested from first page then dynamically set the current page, else set to fromPageToRequest
					if (pageSize > defaultPageSize && fromPageToRequest === 1) {
						setCurrentPage(pageSize / defaultPageSize);
					} else {
						setCurrentPage(fromPageToRequest);
					}

					setTotalNumberOfRecords(result.data.totalNumberOfRecords);
					if (
						result.data.totalNumberOfRecords ==
						incidentList.length + result.data.results.length
					) {
						setHasMoreIncidents(false);
					}
				});
		} else {
			setHasMoreIncidents(false);
		}
	};

	// The InfiniteScroll Component requires a height for it to work  effectively and for it resize relative to the parent.
	useEffect(() => {
		const handleResize = () => {
			setHeight(ref.current.offsetHeight - 20); // reduce height by 20px ottherwise the scrollbar flows past the height of screen.
		};
		handleResize();
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	useEffect(() => {
		getIncidents();
	}, []);

	return (
		<Page className={classes.root} title="Incident Records" style={{ height: "100%" }}>
			<div ref={ref} style={{ backgroundColor: "", height: "100%", overflow: "hidden" }}>
				{/* <p>
					{totalNumberOfRecords} {incidentList.length}
				</p> */}
				{/* n.o of records and length of loaded list */}
				<InfiniteScroll
					dataLength={incidentList.length}
					next={() => {
						getIncidents(currentPage + 1);
					}}
					hasMore={hasMoreIncidents}
					loader={<CardText message={"Loading..."} />}
					height={height + "px"}
					scrollThreshold={0.99}
					style={{ paddingBottom: "10px" }}
				>
					<ScrollToTopOnMount />

					<PageHeaderTabs
						title="Incident Records"
						addTabPath={fromHealthSafetyForm.ADD_INCIDENT_PATH}
						backActionPath={fromHealthSafetyForm.TASK_ROUTE}
					/>
					<div className={classes.filter}>
					<SearchBar onSearch={(s) => {
						setSearchFilter(s);
						// Only trigger search if string is empty (reset) or 3+ characters
						if (s === "" || s.length >= 3) {
							getIncidents(1, true);  // Reset to page 1 and force refresh
						}
					}} />
					</div>

					<PageContent pad>
						{/* <PaginationHeader pagination={incidents} /> */}
						<p style={{ marginTop: 5 }}>
							{incidentList.length > 0 ? totalNumberOfRecords + " records" : "No records loaded"}
						</p>

						{!empty &&
							isDoneFetching &&
							incidentList.map((item, index) => (
								<AccidentCard
									key={index}
									accident={item}
									role={profile.role}
									onAppend={onAppend(item.id)}
									onView={onView(item.id)}
									onDelete={() =>{
										//incidentIndex + 1 to get position in array, then get the next n(pageSize)TH multiple which will be the page size to load
										const pageSizeToLoad =
										Math.ceil((index + 1) / defaultPageSize) * defaultPageSize;

										safetyWiseApi
											.del(`${currentOrganisationGUID}/accident/delete/${item.id}`)
											.then(() => {
												getIncidents(1, true, pageSizeToLoad);
											})}
									}
									onEmail={onEmail(item.id, item.siteId)}
								/>
							))}

						{isFetching && <PaginationLoader height={90} pagination={incidents} />}

						{empty && isDoneFetching && (
							<Typography variant="body1">There are currently no Incidents listed.</Typography>
						)}

						{/* <Pagination
							onGetList={onGetIncidentList}
							pagination={incidents}
							orderBy="createdAt"
							ascending={false}
							searchKey={searchFilter}
						/> */}
					</PageContent>

					{showAppendModal && (
						<AppendIncidentForm
							id={selectedItemId}
							values={incidentList.find((x) => x.id == selectedItemId)}
							incidentList={incidentList}
							onClose={(submitted = false) => {
								setShowAppendModal(false);
								if (submitted) {
									console.log("get here");
									const incidentIndex = incidentList.findIndex((t) => t.id === selectedItemId); // find incidentIndex
									//incidentIndex + 1 to get position in array, then get the next n(pageSize)TH multiple which will be the page size to load
									const pageSizeToLoad =
										Math.ceil((incidentIndex + 1) / defaultPageSize) * defaultPageSize;

									getIncidents(1, true, pageSizeToLoad);
								}
							}}
						/>
					)}
					{showViewModal && (
						<EditIncidentForm
							readOnly
							id={selectedItemId}
							incidentList={incidentList}
							onClose={() => setShowViewModal(false)}
						/>
					)}
					<SendEmailModal
						isOpen={showEmailModal}
						title={"Email Incident Record"}
						onClose={() => setShowEmailModal(false)}
						onSend={(selectedRecipients) => {
							setIsSendingEmail(true);
							safetyWiseApi
								.post(`${currentOrganisationGUID}/accident/email/${selectedItemId}`, {
									toInternalAddresses: selectedRecipients.recipients,
									toExternalAddresses: [
										...selectedRecipients.recipientsOther,
										...selectedRecipients.recipientsCustomerSites
									]
								})
								.then(() => {
									setSuccessMessage("Emails sent successfully.");
								})
								.catch(handleApiError)
								.finally(() => {
									setIsSendingEmail(false);
									setShowEmailModal(false);
								});
						}}
						isSaving={isSendingEmail}
						selectedItemId={selectedItemId}
						siteId={siteId}
						emailListType={"relatedToParticularSite"}
					/>
					<PopupSnackbar
						message={errorMessage}
						coloured
						error
						onExited={() => setErrorMessage("")}
					/>
					<PopupSnackbar
						message={successMessage}
						coloured
						success
						onExited={() => setSuccessMessage("")}
					/>
					{!isDoneFetching && <CardText message={"Loading..."} style={{ marginTop: "20px" }} />}
					{!hasMoreIncidents && <CardText message={"All incidents have been loaded."} />}
					{incidentList.length === 0 && isDoneFetching && (
						<CardText message={"No incidents to show."} />
					)}
				</InfiniteScroll>
			</div>
		</Page>
	);
};

IncidentList.propTypes = {
	incidents: PropTypes.object.isRequired,
	searchFilter: PropTypes.string,
	onDelete: PropTypes.func.isRequired,
	profile: PropTypes.object.isRequired,
	supervisor: PropTypes.string,
	onSearchChanged: PropTypes.func.isRequired,
	onGetIncidentList: PropTypes.func.isRequired
};

export default IncidentList;
