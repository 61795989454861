import React from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Tab, Tabs } from "frame/components";
import { useRouter } from "utils";
import * as fromRoutes from "../actions/types";
import { isStaffUser, isSuperAdminUser, isAccountAdminUser } from "utils";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
	root: {
		[theme.breakpoints.down("xs")]: {
			marginTop: theme.spacing(-3),
		},
	},
}));

const MenuTabs = ({ role }) => {
	const theme = useTheme();
	const classes = useStyles();
	const mobileView = useMediaQuery(theme.breakpoints.down("xs"));
	const { history, match } = useRouter();
	const { tabId } = match.params;

	if (mobileView) {
        return null; // Do not render anything in mobile view
    }

	const getTabPath = (path) => `${fromRoutes.ACCOUNT_PATH}/${path}`;

	const onTabChange = (event, value) => {
		history.push(getTabPath(value)); // todo: dispatch push()
	};

	let tabList = [
		{
			label: "Profile",
			path: fromRoutes.ACCOUNT_PROFILE_PATH,
		},
		{
			label: isSuperAdminUser(role)
				? "Invite Consultant"
				: "Invite Organisation",
			path: fromRoutes.ACCOUNT_INVITE_PATH,
		},
		{
			label: "Organisations",
			path: fromRoutes.ACCOUNT_ORGANISATIONS_PATH,
		},
		{
			label: "Subscription",
			path: fromRoutes.ACCOUNT_SUBSCRIPTION_PATH,
		},
		{
			label: "Reports",
			path: fromRoutes.ACCOUNT_REPORTS_PATH,
		},
		{
			label: "Notifications",
			path: fromRoutes.ACCOUNT_NOTIFICATIONS_PATH,
		},
		{
			label: "Password",
			path: fromRoutes.ACCOUNT_PASSWORD_PATH,
		},
	];

	let staffTabList = [
		{
			label: "Profile",
			path: fromRoutes.ACCOUNT_PROFILE_PATH,
		},
		{
			label: "Organisations",
			path: fromRoutes.ACCOUNT_ORGANISATIONS_PATH,
		},
		{
			label: "Password",
			path: fromRoutes.ACCOUNT_PASSWORD_PATH,
		},
	];

	tabList = isAccountAdminUser(role)
		? tabList.filter((item) => item.label !== "Invite Organisation")
		: isStaffUser(role)
		? staffTabList
		: tabList;

	return (
		<div className={classes.root}>
			{!mobileView && (
				<Tabs
					onChange={onTabChange}
					scrollButtons="auto"
					value={tabId || "profile"}
					variant="scrollable"
				>
					{tabList.map(({ label, path }, index) => (
						<Tab key={index} label={label} value={path} />
					))}
				</Tabs>
			)}
		</div>
	);
};

MenuTabs.propTypes = {
	role: PropTypes.string,
};

export default MenuTabs;
